.copyright{
    &__wrapper{
        text-align: right;
        font-size: 11px;
        margin-top: 30px;
        p, a{
            font-family: "Montserrat light", sans-serif;
            color: #758FA6;
            text-decoration: none;
            margin: 2px 0 0 0;
        }

        a:hover{
            text-decoration: underline;
        }
    }
}