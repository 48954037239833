.no-data{
    &-wrapper{
        --safe-area-inset: calc(env(safe-area-inset-top) + env(safe-area-inset-bottom));
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 100px 35px 80px;
        height: calc(100vh - (var(--safe-area-inset)));
        
        h3{
            color: #fff;
            margin: 20px 0 15px;
            font-weight: 500;
        }

        p{
            color: #fff;
            flex-grow: 1;
            text-align: center;
            margin: 0;
            line-height: 1.3;
        }
    }

    &-icon{
        width: 50px;
        height: 50px;
    }

   
}

.no-data-wrapper.no-data-profile{
    border-radius: 3px;
    max-height: 100%;

    .button{
        margin-bottom: 0 !important;
    } 
}