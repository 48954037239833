.tutorial{
    position: absolute;
    left: 0;
    top: env(safe-area-inset-top);
    bottom: env(safe-area-inset-bottom);
    right: 0;
    text-align: center;
    z-index: 250;display: flex;
    flex-direction: column;
    align-items: center;
    
    @media screen and (min-width: 768px){
        flex-direction: column-reverse;
    }

    h1{
        color: #fff;
        margin: 0;
        font-size: 25px;
        font-weight: 500;
    }

    &-inner{
        flex-grow: 1;
        padding: 60px 40px 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    &-navigation{
        height: 66px;
        width: 100%;
        &-inner{
            background:#F3F3F3;
            height: 100%;
            width: 100%;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, .2);
            display: flex;
            justify-content: center;
        }

        &-left, &-right{
            display: none;
        }

        &-center{
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &-item{
            min-width: 110px;
            width: 25%;
            height: 100%;
            border-bottom: transparent 3px solid;
            display: flex;
            justify-content: center;
            align-items: center;

            svg{
                fill: inherit;
                width: 38px;
                height: 38px;
                margin-left: 3px;

                *{
                    fill: inherit;
                }
            }

            span{
                display: none;
            }
        }
    }

    &-icon{
        width: 42px;
        height: 42px;
        margin: 0 auto 15px;

        &.icon-0{
            width: 45px;
            height: 45px;
            margin-bottom: 12px;
        }

        svg {
            fill: #fff;
        }
    }

    &-content{
        color: #fff;
        flex-grow: 1;
        font-size: 14px;
        line-height: 1.5;
        padding-top: 15px;
        font-family: "Montserrat light", sans-serif;
    }

    &-button{
        &-wrapper{
            height: 55px;
        }
    }

    .button{
        background: #fff;

        @media screen and(min-width: 768px){
            max-width: 300px;
        }
        &--secondary{
            height: 100%;
            line-height: 55px;
            background: transparent;
            appearance: none;
            color: #fff;
            font-size: 14px;
            font-family: "Montserrat light", sans-serif;
        }
    }

    @media screen and (min-width: 768px){

        &-inner{
            padding: 100px 60px 70px;
        }
        &-content{
            font-size: 16px;
            line-height: 1.7;
        }


        &-navigation{
            &-left, &-right{
                display: block;
                width: 220px;
            }

            &-inner{
                justify-content: space-between;
                padding: 0 15px;
            }

            &-item{
                padding: 0 10px;
                margin: 0 10px;
                width: auto;
                min-width: 0;
                justify-content: space-between;
                
                svg{
                    margin-left: 0;
                }

                span{
                    display: block;
                    padding-left: 5px;
                    font-size: 16px;
                    color: inherit;
                }
                
                &:nth-child(3){
                    display: none;
                }
            }
            &-right{
                display: flex;
                justify-content: flex-end;

                .tutorial-navigation-item{
                    padding: 0;
                    margin: 0;
                }

            }
        }

        

        &-button-wrapper{
            height: 70px;
        }

        .button--secondary{
            font-size: 16px;
            line-height: 70px;;
        }
    }
}
