@import '../../../common/__common.scss';


.footer {
  text-align: center;
  opacity: .7;

  a {
    display: inline-block;
    text-align: center;
    white-space: nowrap;
    padding: 0 5px;
    font-size: 11px;

    *{
      font-family: "Montserrat light", sans-serif;
    }
  }
}
