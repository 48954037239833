@import '../../../common/__common.scss';

.page {
  & > .previousPageAction .round-button {
    grid-column: 1;
    margin-left: 10px;
  }

  & > *:not(.pageAction) {
    grid-column: 2;
  }

  & > .previousPageAction .round-button {
    grid-column: 3;
    margin-right: 10px;
  }

  & > .pageAction .round-button {
    // TODO some of this has to be defined in styleguide
    @include smartphone() {
      display: none;
    }
    grid-template-columns: initial;
    grid-auto-columns: 30px 1fr;
    margin-top: 130px;

    &-icon {
      grid-column: 1;
      width: 30px;
      height: 30px;

      & svg {
        width: 20px;
        height: 20px;
      }
    }

    &-text {
      grid-column: 2;
    }
  }
}

.content {
  &--is-splash {
    background: $primary-accent-dark;
    --ion-background-color: none;
  }

  &--is-success {
    background: $success;
    --ion-background-color: none;
    @include smartphone() {
      background: #f3f3f3;
    }
  }

  &--is-danger {
    background: $danger;
    --ion-background-color: none;
    @include smartphone() {
      background: #f3f3f3;
    }
  }
}
.page-profile .footer {
  z-index: 1;
}