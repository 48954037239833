@import "../../__common";

.result {
  display: flex;
  flex-direction: column;
  align-items: stretch;

  &__item{
    transition: .2s;

    &.no-dropdown .result-learning-area__arrow{
      display: none;
    }
  }

  &__section{
    &-header{
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 13px;
      margin-top: 0;
      margin-bottom: 40px;
      color:#758FA6;
      font-family: "Montserrat light", sans-serif;

      &-wrapper{
        margin-top: 20px;
      }

      &:last-child{
        margin-bottom: 15px;
      }

      &.hidden{
        display: none;
      }

      &__label{
        flex-shrink: 0;
        flex-grow: 0;
      }

      &__subinfo{
        flex-grow: 1;
        flex-shrink: 1;
        color: #4a4a4a;
        text-align: right;
        padding-left: 10px;
        font-family: "Montserrat light", sans-serif;

        &.error{
          color: #ff6839;
        }

        &.success{
          color: #7be03e;
        }
      }
    }
  }

  &-learning-area {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    line-height: 68px;
    cursor: pointer;
    border-top: 1px solid $divider;

    &--is-expanded {
      svg {
        transition-duration: 0.25s;
        transform: rotate(-180deg);
      }
    }

    p {
      margin: 0;
      padding-right: 10px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__label {
      grid-column: 1;
      font-size: 13px;
      color: #758FA6;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex-grow: 1;
      flex-shrink: 1;
      margin-right: 20px;
      font-family: "Montserrat light", sans-serif;

      *{
        font-family: "Montserrat light", sans-serif;
      }
    }

    &__points {
      grid-column: 2;
      text-align: right;
      font-size: 13px;
      white-space: nowrap;
      color: $danger;
      flex-shrink: 0;
      font-family: "Montserrat light", sans-serif;

      &--is-success {
        color: $success;
      }
    }
    div{
      flex-shrink: 0;
    }
    &__arrow {
      display: inline-block;
      margin-left: 10px;
      svg {
        transition-duration: 0.25s;
        width: 12px;
        position: relative;
        top: 4px;

        path {
          stroke: $tertiary;
        }
      }
    }

    &__questions {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(20px, 40px));
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      justify-content: flex-start;
      padding: 0;
      .circle{
        font-family: "Montserrat light", sans-serif;
        width: 40px;
        height: 40px;
        padding: 0;
        line-height: 40px;
        text-align: center;
      }
    }
  }

  & > div:nth-child(2) > .result-learning-area {
    border-top: none;
  }

  &__incorrect-questions {
    margin: 30px 10px 0;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(38px, 38px));
    grid-row-gap: 18px;
    grid-column-gap: 18px;
    justify-content: center;
  }
}
