.is-mobile,
.plt-mobile {
  @media screen and (min-width: 0px) and (max-width: 700px) {
    @media only screen and (orientation: landscape) {
      .exam-wizard {
        max-width: 550px !important;
      }

      .exam-wizard__panel {
        width: 550px !important;
      }

      .exam-wizard__header {
        width: 550px !important;
        padding: 16px 20px 0 0 !important;
      }
    }
  }

  .confirm-finish-exercise__attention {
    margin-bottom: 0;
  }

  ion-toolbar {
    display: none;
  }

  .onboarding {
    min-height: 85%;
    @media screen and (min-width: 0px) and (max-width: 350px) {
      height: 550px !important;
      min-height: 550px !important;
      max-width: 100% !important;
      padding-top: 0 !important;
    }

    &__arrows {
      display: none;
    }

    &__content {
      p {
        padding-top: 115%;
        top: 0;
      }
    }

    &__footer {
      width: 110%;
      max-width: 110%;
    }
  }

  ion-title {
    display: none !important;
  }

  .swiper-wrapper {
    ion-slide {
      margin-bottom: 24px;
    }
  }

  .profile .profile__content {
    align-items: inherit;
  }

  .navigation-tab-wrapper__left {
    display: flex;
  }

  .navigation-tab-wrapper__item {
    margin: 0;
    width: 25%;
  }

  .navigation-tab-wrapper__item.profile {
    margin: 0;
  }
  .learning-content {
    p.paragraph {
      margin: 0;
      font-size: 16px;
      line-height: 1.7;
      width: 100%;
    }

    ul {
      padding: 0 0 0 10px;
      margin: 0;
    }

    hr {
      border-bottom: 2px solid #ced6dc;
      width: 50px;
    }
  }

  .results-chart__header {
    h2 span {
      font-size: 16px !important;
    }
  }

  .results-chart__buttons {
    .button {
      display: block;

      span {
        position: absolute;
        right: 30px;
      }

      .button-icon-right svg {
        left: 10px;
        top: 22px !important;
        position: absolute;
      }
    }
  }

  @media screen and (min-width: 900px) {
    .tablet.page-learning {
      .button {
        display: grid;
      }
    }
  }

  .button {
    width: 100%;
    display: flex;
    position: relative;
    justify-content: center;

    .button-icon-right {
      position: absolute;
      top: 0;
      right: 0;
    }

    .button-icon-left {
      svg {
        transform: inherit !important;
      }
    }
  }

  .sidebar__links {
    .only-mobile {
      display: flex !important;
    }

    .only-desktop {
      display: none !important;
    }
  }

  .banner {
    display: block;
    word-break: break-word !important;
    hyphens: auto;

    @media screen and (min-width: 0px) and (max-width: 330px) {
      width: 300px !important;
    }

    &-wrapper.knownuser .banner_header-action {
      display: none !important;
    }

    &_header {
      display: flex;
      justify-content: center;
      position: relative;

      &-action {
        position: absolute;
        top: 16px;
        left: 0;
      }
    }

    &_body {
      @media screen and (min-width: 0px) and (max-width: 330px) {
        padding: 20px !important;
      }
    }
  }

  .exam-wizard {
    &__header {
      justify-content: space-between;
      background: white;
      &-item {
        justify-content: center;
        display: flex;

        .stopwatch {
          padding: 0;
        }
      }
    }
  }

  .tab-nav {
    background: red;
    margin-bottom: constant(safe-area-inset-bottom);
  }

  ion-slide {
    .banner_header {
      span {
        display: block;
        align-self: center;
        justify-content: center;
        position: absolute;
        top: 22px;
        left: 50px;
        margin: auto;
      }
    }
  }

  &.page-learning-content {
    .banner_header {
      span {
        width: 100%;
        text-align: center;
        left: inherit;
      }
    }

    .page {
      display: block;
    }
  }

  &.page-learning {
    .page {
      display: block;
      max-width: 750px;
    }

    .learning-content {
      h1 {
        font-size: 24px;
        margin: 10px 0;
      }
    }

    .list-item_content {
      .paragraph {
        // line-height: 25px;
      }

      .button {
        display: none;
      }
    }
  }

  .ReactModal__Overlay {
    .ReactModal__Content {
      .result-question__header {
        width: 100%;
        margin-left: 0% !important;
        height: 32px;
        line-height: 34px;
      }

      .result-question-content__body {
        width: 100%;
        padding: 20px 5px 20px 5px !important;

        h2 {
          font-size: 20px;
          padding: 0;
          margin: 20px 0;
        }
      }
    }

    .result-question__header-close {
      .round-button-icon {
        svg {
          width: 22px !important;
          height: 22px !important;
        }
      }
    }
  }

  .system-stop-watch .stopwatch {
    position: absolute;
    top: 90px;
    left: 36%;
  }

  .onboarding__content p {
    position: unset;
    text-align: center;
  }

  .exercise-wizard {
    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-self: center;

      .button {
        align-self: center;
        width: 65% !important;
        margin-left: -5px;
      }
    }
  }
}

.onboarding.onboarding--step4 {
  .onboarding__arrows {
    margin-top: 10px !important;
  }
}

.exam-completed {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 550px;
  overflow: hidden;
}

.exam-completed__header--is-danger {
  background-color: #ff6839;
  height: 70%;
}

.exam-completed__header-description {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 90px;
}

.exercise-completed__header-description {
  font-size: 24px;
  font-weight: bold;
  margin: 60px 0 !important;
}

.is-mobile .banner_header,
.plt-mobile .banner_header {
  padding: 0 0px !important;
}

.is-mobile {
  @media only screen and (orientation: landscape) {
    .exercise-wizard {
      //width: 700px !important;
    }
  }
  @media only screen and (orientation: portrait) {
    .exercise-wizard {
      //width: 360px !important;
    }
  }
}

.is-mobile,
.plt-mobile,
.is-android {
  .page-lost-password .banner .lost-password-form__inputs {
    padding: 0px 0 0px 0;
  }

  .ReactModal__Content.exam-navigation-content {
    width: 100% !important;

    .exam-navigation-content__body {
      padding: 0 30px 50px 50px !important;
    }
  }
}

.plt-iphone {
  .exam-navigation-content__body {
    padding: 0 20px 50px 50px;
  }
}

.page-learning-content {
  .image {
    margin: 1em 0px;
  }
}

.learning-content .paragraph li {
  color: #4a4a4a;
  line-height: 35px;
}
.learning-content .paragraph h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  line-height: 35px;
  font-family: 'Montserrat Light', sans-serif;
  color: #4a4a4a;
}

.list-item_content-description .paragraph > div,
.list-item_content-description .paragraph > span{
  font: 16px "Montserrat light", sans-serif;
  color: #4a4a4a;
}

.list-item_content-description > span {
  text-transform: uppercase;
  font-family: 'Montserrat Light';
}

.list-item_content-description .paragraph span.inner{
  text-transform: none;
  line-height: 25px;
  display: block;
  margin-top: 0;
  font-family: "Montserrat light", sans-serif;
}

.list-item_content-description .paragraph span{
  margin-top: 10px;
}

.banner_header-action {
  transform: rotate(180deg);

  svg {
    transform: none !important;
  }
}
