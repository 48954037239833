@import '../../__common';

.stopwatch {
  display: grid;

  &__progress {
    grid-template-rows: 100px;
    margin: 0 auto;
    &-time {
      font-size: 12px;
      position: relative;
      color: $secondary;
      text-align: center;
      top: -55px
    }
    .CircularProgressbar {
      height: 100px;
      width: 100px;
      &-path {
        stroke: $secondary;
      }
    }
  }
}
