.sidebar {
  background: #fff;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
  border-radius: 0;
  padding: 30px;
  min-height: 100vh;
  min-width: 320px;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  &__profile-image {
    padding-bottom: 30px;

    .image-upload {
      margin: 0;
      padding: 0;

      .upload-label {
        width: 260px;
        color: #9b9b9b;
        display: flex;
        text-transform: uppercase;
        cursor: pointer;
      }
    }

    .profile-image-wrapper {
      width: 50px;
      height: 50px;
      margin-right: 20px;
      overflow: hidden;
      -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
      flex: 0 0 auto;
      background-color: #20415d;
      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;
      border-radius: 50px;
    }

    .profile-image-name {
      color: #20415d;
      display: inline-block;
      line-height: 50px;
      -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
      flex: 1 1 auto;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: 'Montserrat Bold';
      font-size: 19px;
      text-transform: capitalize;
    }
  }

  &__ {
    width: 100%;
    flex-grow: 1;
    .profile-details-form {
      margin-bottom: 30px;

      .field-item {
        .label {
          color: #9b9b9b;
          font-family: 'Montserrat Light';
          display: block;
          text-transform: uppercase;
          cursor: pointer;
          font-size: 7px;
        }

        input {
          padding: 0;
          margin: 0 0 30px;
          display: block;
          width: 100%;
          height: 30px;
          background: 0 0;
          border: none;
          border-bottom: 1px solid #ced6dc;
          color: #20415d;
          font-size: 11px;
        }

        &.submit-btn {
          .button {
            width: 100%;
          }

          .button span {
            font-size: 11px;
          }
        }

        &.hidden {
          .button {
            height: 0px !important;
            display: block;
            overflow: hidden;
          }
        }

        &.animate {
          .button {
            height: 50px;
            transition: height 0.5s;
          }
        }
      }

      .group-fields {
        display: flex;
        justify-content: space-between;

        .field-item {
          width: 48%;
        }
      }

    }
  }

  &__links {
    a,
    button,
    .user-email {
      display: block;
      border-bottom: 1px solid rgba(32, 65, 93, 0.2);
      text-decoration: none;
      font: 12px "Montserrat light";
      color: #20415d;
      padding: 15px 0;
      background: none;
      width: 100%;
      text-align: left;
      justify-content: space-between;

      *{
        font-family: "Montserrat light";
      }

      &:last-child {
        border-bottom: none;
      }

      &.danger {
        color: #ff6839;
      }

      .version-info {
        margin-right: 15px;
        color: #738ea5;
        font-size: 10px;
      }

      
    }

    &.has-icon {
      a,
      button,
      .user-email {
        display: flex;
        align-items: center;
        padding: 17px 0 !important;
        outline: none;

        .round-button {
          display: block;
          margin-right: -7px;
        }

        &.offline-mode{
          display: none;
        }
        
        &.link-disabled{
          cursor: default;
          color: rgba(0,0,0,.4);
          pointer-events: none;

          .round-button{
            opacity: 0;
          }
        }
      }
      .user-email{
        flex-direction: column;
        align-items: flex-start;
        padding: 10px 0 0 !important;
        span:first-child{
          color:#758FA6;
        }
        border-bottom: 1px solid transparent;
        line-height: 24px;

        @media screen and (min-width: 768px){
          flex-direction: row;
          align-items: center;
          padding: 17px 0 !important;
        }
      }
    }

    .only-mobile {
      display: none !important;
    }

   
  }

  .info-area{
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &__information-box{
    flex-grow: 1;
    width: 100%;
  }


  @media screen and (min-width: 1050px){
    .copyright__wrapper{
      display: none;
    }
  }
}

.plt-mobile .sidebar__links.has-icon a.offline-mode{
  display: flex;
}

.plt-mobile.plt-mobileweb .sidebar__links.has-icon a.offline-mode{
  display: none;
}