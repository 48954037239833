@import "../../__common";

.exam-card {
  display: grid;
  text-align: center;
  margin: 14px 14px 20px 14px;
  background: $white;
  border-radius: 6px;
  box-shadow: $box-shadow;
  width: calc(100% - 28px);

  &-header {
    display: grid;
    height: 70px;
    padding: 0 50px;
    border-bottom: 1px solid $divider;
    align-content: center;
    justify-content: left;
  }
  &-body {
    padding: 15px;
    word-break: break-word;
    hyphens: auto;
  }

  .text {
    font-size: 13px;
    color: #738ea5;
    font-family: "Montserrat light", sans-serif;
  }
  .group-border {
    margin: 25px;
    textarea {
      font-size: 32px;
      text-align: center;
      color: $primary-accent-dark;
      font-family: $font-bold;
      font-weight: bold;
    }
  }
  .divider {
    margin: 25px auto;
  }

  .button {
    margin: auto auto 10px auto;
    max-width: 320px;
    width: 100%;

    &-icon-right {
      margin-right: 8px;

      svg {
        top: 4px;
        width: 42px;
        height: 42px;
        right: -4px;
      }
    }
  }

  @media screen and (min-width: 1050px) {
    width: calc(100% - 20px);
    margin: 20px 10px;
  }
}
