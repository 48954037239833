@import "../../__common";


.register-form {
  height: 100%;
  display: flex;
  // grid-template-rows: 1fr 1fr;
  flex-direction: column;

  &__inputs{
    flex-grow: 1;
  }

  &__inputs,
  &__actions {
    // display: grid;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
  &__actions {
    padding-bottom: 40px;
    // grid-template-rows: 1fr 0.5fr;
    .button {
      margin: 40px auto 30px;
    }

    a.link {
      cursor: pointer;
      text-transform: uppercase;

      *{
        font-family: "Montserrat light", sans-serif;
      }
    }
  }

  .group {
    margin-bottom: 40px;

    .bar:before,
    .bar:after {
      height: 1px !important;
    }

    label {
      color: $login-form-label-color;
      font-size: 14px;
      font-family: $font-light;
      display: block;
      text-transform: uppercase;
      cursor: pointer;
      top: -15px;
      left: 0;

    }
  }

  span.label {
    color: #9b9b9b;
    margin-left: 10px;
    margin-bottom: 7px;
    display: inline-block;
    font-weight: 400;
    font-size: 15px;
    font-family: "Montserrat light", sans-serif;

  }

  input {
    font-weight: 400;
    font-family: "Montserrat";
    margin-bottom: 20px;
    display: block;
    width: 100%;
    height: 47px;
    background: 0 0;
    background-color: #fafafa;
    border: 1px solid #fafafa;
    border-radius: 3px;
    color: #20415d;
    font-size: 15px;
    padding: 10px;
    font-family: "Montserrat light", sans-serif;


    &:focus{
      border: 1px solid #339bf2;
    }

    &:-internal-autofill-selected {
      -webkit-appearance: menulist-button;
      background-color: $login-form-autofill-color !important;
      background-image: none !important;
      font-size: 15px !important;
      font-family: $font-light !important;
    }
  }

  &__field{
    &.acceptance{
      display: flex;

      input{
        width: 17px;
        flex-shrink: 0;
        border: none;
        height: 17px;
        margin-right: 10px;
        background: #f4f4f4;
        -webkit-appearance: none;
        appearance: none;

        &:checked{
          background-color: #339bf2;
          background-image: url("../../../assets/icons/check.svg");
          background-size: contain;
        }
      }

      span, span *{
        line-height: 1.5;
        font-size: 14px;
        font-family: "Montserrat light", sans-serif;
      }
    }
  }
  

  .image-upload {
    margin: auto;
    width: 100%;
    padding-bottom: 20px;
  }
}
