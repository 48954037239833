@import "../../__common";

.select {
  display: grid;

  svg {
    margin: auto 0;

    path {
      width: 24px;
    }
  }

  &__label {
    display: grid;
    position: relative;
    left: 20px;
  }

  &__input {
    display: grid;
    font-size: 16px;
    font-family: $font-normal;
    background-color: #f3f3f3;
    background-image: url(../../../assets/icons/chevron-down.svg);
    background-position-x: calc(100% - 20px);
    background-position-y: 50%;
    background-repeat: no-repeat;
    background-size: auto 50%;
    border: none;
    box-sizing: border-box;
    color: rgb(51, 51, 51);
    cursor: default;
    display: block;
    font-weight: 700;
    height: 52px;
    letter-spacing: normal;
    overflow-x: visible;
    overflow-y: visible;
    padding: 8px 45px 8px 20px;
    text-rendering: auto;
    text-shadow: none;
    text-transform: none;
    white-space: pre;
    width: 100%;
    word-spacing: 0px;
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    outline: none;
  }
}
