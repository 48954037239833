
.link {
  display: grid;
  text-decoration: none;
  align-content: center;
  .text {
    display: grid;
    font-size: 12px;
    text-align: center;
  }
}
