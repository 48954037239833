.offlineSettingItem{
    padding: 20px 0;
    border-bottom: 1px solid #CED6DC;

    &:last-of-type{
        border-bottom: 1px solid transparent;
    }

    &--header{
        display: flex;
        justify-content: space-between;
        align-items: center;

        &.sub{
            padding-top: 15px;
        }

        label{
            left: 0;
            top: 0;
        }

        .switch {
            position: relative;
            display: inline-block;
            width: 60px;
            height: 34px;
        }

        .switch input {
            opacity: 0;
            width: 0;
            height: 0;
        }

        .slider {
            position: absolute;
            cursor: pointer;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color:#CED6DC;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 34px;
        }

        .slider:before {
            position: absolute;
            content: "";
            height: 26px;
            width: 26px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
            border-radius: 50%;
        }

        input:checked + .slider {
            background-color: #59c11a;
        }

        input:focus + .slider {
            box-shadow: 0 0 1px #59c11a;
        }

        input:checked + .slider:before {
            -webkit-transform: translateX(26px);
            -ms-transform: translateX(26px);
            transform: translateX(26px);
        }
    }

    &--title{
        font-weight: 500;
        margin: 0;
        font-family: "Montserrat Bold", sans-serif;

        &.sub{
            font-weight: 500;
            font-family: "Montserrat", sans-serif;
            font-size: 15px;
        }
    }  
}


.offlineSettings{
    &--actions{
        padding: 20px 0 40px 0;
    }

    &--diskspace{
        text-align: center;
        font-size: 14px;
        text-transform: uppercase;
        margin-bottom: 20px;

        p{
            margin: 0;

            &.space{
                color: #339bf2;
            }
        }

        &-spinner{
            height: 21px;
        }
    }
}

.offlinesetting-wrapper{
    flex-grow: 1;
}