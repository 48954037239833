@import "../../../_common";

body.error--is-modal-open {
  overflow: hidden;
}

.error {
  &-overlay {
    background: rgba(32, 65, 93, .95) !important;
    & .error-content {
      &__body {
        display: grid;
        justify-content: center;
        text-align: center;

        & h2 {
          margin: 0 0 10px;
          font-size: 48px;
          font-family: $font-bold;
          font-weight: bold;
        }

        & .paragraph {
          & .error-description {
            margin: 0 0 20px 0;
          }

          & .error-code {
            margin: 20px 0 0 0;
          }

          & * {
            font-size: 16px;
            display: grid;
            line-height: 20px;
          }
        }

        & .button {
          margin: 20px auto 0 auto;
        }

        & * {
          color: $white;
        }
      }
    }
  }
}
