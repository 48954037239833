
$safe-area: calc(env(safe-area-inset-top) + env(safe-area-inset-bottom));
$safe-area-top: env(safe-area-inset-top);
$safe-area-bottom: env(safe-area-inset-bottom);


body {
  background: #000;
}

*{
  font-family: "Montserrat";
}

.ion-page {
  padding-top: $safe-area-top;
  padding-bottom: $safe-area-bottom;
}

body > iframe {
  display: none;
}

p.paragraph,
p.paragraph p {
  word-break: break-word !important;
  hyphens: auto;
}

h1,
h2,
h3 {
  font-weight: bold;
  word-break: break-word !important;
  hyphens: auto;
}

.page{
  height: 100%;
  margin: 0;
}

.page-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 0;

  @media screen and (min-width: 768px){
    padding-top: 65px;
  }
}

.is-auth .page-wrapper{
  @media screen and (min-width: 768px){
    padding-top: 0;
  }
}

.cards-page{
  padding-bottom: 66px;

  @media screen and (min-width: 768px){
    padding-bottom: 0;
    .grid{
      display: flex;
      flex-wrap: wrap;
      column-gap: 0;
    }
  }
}

@media screen and (max-width: 650px) {
  .page-change-password {
    .page-wrapper {
      --safe-area-inset-top: env(safe-area-inset-top);
      min-height: calc(100vh - (var(--safe-area-inset-top)));
      position: relative;
    }
  }
}

@media screen and (min-width: 0) and (max-width: 350px) {
  .footer a.link {
    padding: 0 5px;
  }
}

ion-content {
  --ion-background-color: #f3f3f3;
  --keyboard-offset: 0 !important;
}

.swiper-container {
  margin: 0;
  width: 100%;
}

.swiper-slide {
  align-items: flex-start;
  padding: 0 8px 8px 8px;
}

ion-tab-bar {
  &.hidden {
    display: none;
  }
}

.license-is-invalid {
  &--actions {
    display: flex;
    justify-content: space-between;
    @media screen and (min-width: 0px) and (max-width: 500px) {
      display: block;
      .button {
        width: 100%;
        margin-bottom: 10px;
      }
    }
  }
}

.page-login .banner,
.page-lost-password .banner,
.page-register .banner {

  @media screen and (min-width: 1110px) {
    width: 390px !important;
    height: 692px;
  }

  &__body-content {
    padding: 0 60px;
  }
}

.ReactModal__Content {
  .error-content {
    &__body {
      display: flex !important;
      justify-content: space-around !important;
      flex-direction: column;
      height: 100%;
      width: 100%;
      margin: auto;
      padding: 0 30px;

      h2 {
        font-size: 32px !important;
        @media screen and (min-width: 320px) and (max-width: 370px) {
          font-size: 26px !important;
        }
      }
    }
  }

  @media screen and (min-width: 0px) and (max-width: 500px) {
    width: 90%;
    display: block !important;

    &.exam-navigation-content {
      display: grid !important;
    }

    .error-content__body {
      padding: 0 !important;
      margin: 0 !important;

      .paragraph {
        margin: 50px 0;

        .error-description {
          margin: 0;
        }
      }
    }
  }

  @media screen and (min-width: 768px){
     &.exam-navigation-content {
      max-width: 500px !important;
      height: auto;
      margin: 0 auto !important;
      position: relative;
      top: 50% !important;
      transform: translateY(-50%) !important;

      .exam-navigation__header{
        margin-top: 0;
      }
    }
  }
}

.modal {
  > div:first-child {
    align-items: center !important;
    justify-content: center !important;
    height: 100% !important;
    width: 100% !important;
    display: flex !important;
  }

  .overlay {
    margin: 4% 17px;
    max-width: 470px;
    min-width: 350px;
    max-height: 96%;
    background: #fff;
    overflow: auto;
    border-radius: 6px;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    text-align: center;

    strong {
      color: #20415d;
    }

    .svg {
      margin: 20px auto;
    }

    hr {
      margin: 30px 30px 10px;
      border-color: #738ea5;
      opacity: 0.5;
      border-width: 0;
      height: 1px;
      border-bottom-width: 1px;
    }

    h4 {
      margin: 0 auto 20px;
      padding: 0 5%;
      font: 24px 'Montserrat Bold';
      color: #20415d;
    }

    p {
      padding: 0 5%;
      font: 13px Montserrat;
      color: #738ea5;
      line-height: 20px;
    }

    .actions {
      height: 45px;
      padding: 0 40px 15px;
      display: flex;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: space-between;
      font: 12px 'Montserrat Bold';
      color: #738ea5;

      a,
      button {
        align-self: center;
        text-decoration: none;
        text-transform: uppercase;
        color: #738ea5;
        background: none;
        border: none;
        cursor: pointer;
        outline: none;
      }

      &.single {
        justify-content: center !important;

        a,
        button {
          strong {
            color: #7be03e !important;
          }
        }
      }
    }
  }
}

.app-button-actions {
  height: 45px;
  padding: 0;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: space-between;
  font: 12px 'Montserrat Bold';
  border-top: solid 1px #738ea5;
  color: #738ea5;
  width: 100%;
  margin-top: 30px;
}

a.app-button,
button.app-button {
  align-self: center;
  text-decoration: none;
  text-transform: uppercase;
  color: #738ea5;
  background: none;
  border: none;
  cursor: pointer;
  outline: none;

  strong {
    color: #20415d !important;
  }
}

.register-form {
  .image-upload {
    margin: auto !important;
  }
}

.line-chart {
  canvas {
    width: 100% !important;
    height: 100% !important;
  }
}

.results-chart__buttons {
  .button {
    letter-spacing: 0;
    color: #20415d !important;
  }
}

.results-chart {
  display: block;
  width: 100%;
}

.exercise-wizard {
  .round-button-icon {
    svg {
      width: 22px !important;
      height: 22px !important;
    }
  }
}

.is-mobile .confirm-finish-exercise-content__body,
.plt-mobile .confirm-finish-exercise-content__body {
  // height: 350px !important;
}

.page-lost-password {
  .banner_body {
    .description {
      text-align: center;
      font-family: 'Montserrat Light';
    }
  }
}

.exam-navigation__header-item .round-button .round-button-icon svg {
  width: 22px !important;
  height: 22px !important;
}

.paragraph {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.h1 {
  overflow-wrap: break-word;
  word-wrap: break-word;
}

.exercise-completed {
  overflow: hidden;
}
.page-learning {
  .page {
    width: 100%;
  }
  .CircularProgressbar {
    -webkit-transform: rotateX(0deg) rotateY(180deg);
    -moz-transform: rotateX(0deg) rotateY(180deg);
    -ms-transform: rotateX(0deg) rotateY(180deg);
    -o-transform: rotateX(0deg) rotateY(180deg);
    transform: rotateX(0deg) rotateY(180deg);
  }
  .CircularProgressbar-path {
    stroke: #339bf2 !important;
  }
}

.page-profile-statistics {
  .footer {
    padding: 0 !important;
  }
}

.content__arrow {
  justify-content: space-between;
  position: absolute;
  z-index: 555;
  top: 50%;
  display: none;

  .round-button{
    display: block;
  }

  @media screen and (min-width: 768px){
    display: flex;
  }

  &__next{
    right: 20px;
  }

  &__prev{
    left: 20px;
  }
}

.navigation-tab-wrapper__item.profile {
  svg {
    position: relative;
    left: 0.1px;
  }
}

.onboarding .onboarding__progress-dots ul li:before {
  border-radius: 4px;
}

.result .result__incorrect-questions {
  margin: 30px 10px !important;
}

#loading-overlay {
  background-image: url('./loader.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

  @media screen and (min-width: 1024px) {
    background-image: url('./loader-desktop.png');
  }
}

.learning-more-links-wrapper {
  display: none;
  @media screen and (min-width: 768px) {
    display: block;
    margin: 20px 0 20px 0;

    .buttons-wrapper {
      margin-top: 40px;
      padding-bottom: 40px;
      display: flex;
      justify-content: center;
      flex-direction: row;
      align-items: center;

      .button {
        margin: 0 25px;

        &--has-icon-right {
          grid-template-columns: 50px 1fr 50px;
        }

        .button-text {
          padding: 0 35px;
        }

        svg {
          position: relative;
          top: 4px;
          width: 42px;
          height: 42px;
          right: -4px;
        }
      }
    }
  }
}

.exam-card h2 {
  margin: 30px 0;
}

.banner_header span span {
  display: inline-block;
  width: 100%;
  padding: 0 32px !important;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.banner_header img {
  width: 20px !important;
  max-width: 20px !important;
}

.banner_header span,
.banner_header span p {
  padding: 0 !important;
}

#image-container {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: env(safe-area-inset-top);
  bottom: env(safe-area-inset-bottom);
  left: 0;
  right: 0;
  background: white;
  z-index: 9999;
  overflow: scroll;

  img {
    max-width: 90%;
    max-height: 90%;
    height: auto;
    width: auto;
}
}
