.profile {
  display: flex;
  display: flex;
  flex-direction: column;
  height: 100%;

  &-page {
    &-mobile {
      display: flex;
      flex-direction: column;
      height: 100%;
      @media screen and (min-width: 1050px) {
        display: none;
      }
    }
    &-desktop {
      display: none;
      @media screen and (min-width: 1050px) {
        display: flex;
        height: calc(100vh - 66px);
        overflow: hidden;
      }
    }
  }

  .profile_header {
    background: #fff;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
    border-radius: 0;
    z-index: 12;
    flex-shrink: 0;

    .sidebar__profile-image {
      padding: 25px 0 8px 34px;
    }
  }

  .tab-navigation {
    display: flex;
    justify-content: space-around;
    margin: 0;
    padding: 0;

    li {
      width: 33%;
    }
  }

  .profile__content {
    padding: 30px 30px 85px;
    flex-grow: 1;
    overflow-y: auto;
    overflow-x: hidden;

    .sidebar__links.has-icon button {
      justify-content: space-between;
    }

    @media screen and (min-width: 768px) {
      padding: 30px;
    }
  }

  .profile__content .sidebar__links {
    button.back-button {
      justify-content: flex-start;
      color: #758fa6;
      border-bottom: 1px solid transparent;
      .round-button {
        margin-left: -7px;
        margin-right: 0;
      }

      @media screen and (min-width: 1050px) {
        display: none;
      }
    }
  }

  .profile__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .sidebar__profile-data {
      flex-grow: 1;
    }

    @media screen and (min-width: 1050px) {
      .sidebar__links.has-icon button {
        justify-content: flex-start;
      }
    }
  }

  .no-data-statistics {
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #738ea5;
  }
}

.page-profile {
  .page {
    margin: 0;
    display: block;
    max-width: inherit !important;
  }
}

.profile-edit-password-form {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .form-wrapper {
    flex-grow: 1;
  }

  h2 {
    margin: 20px 0 0 0 !important;
    font-size: 30px;

    @media screen and (min-width: 0px) and (max-width: 500px) {
      font-size: 24px;
      padding-left: 20px;
    }
  }

  .field-item {
    color: #9b9b9b;
    font: 14px 'Montserrat Light';
    display: block;
    cursor: pointer;
    font-weight: 400;

    span {
      margin-left: 10px;
      margin-bottom: 5px;
      display: inline-block;
      font-family: 'Montserrat light';
    }

    input {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      height: 47px;
      background: 0 0;
      background-color: #fafafa;
      border: 1px solid #fafafa;
      border-radius: 3px;
      color: #4a4a4a;
      font-size: 15px;
      padding: 10px;

      &:focus {
        border: 1px solid #339bf2;
      }
    }

    &.has-error {
      input {
        border-bottom: 1px solid red;
      }
    }
  }

  .button {
    letter-spacing: 1px;
    margin: 0 auto 20px auto;
    @media screen and (min-width: 768px) {
      max-width: 300px;
    }
  }

  @media screen and (min-width: 1050px) {
    &__sidebar {
      height: 100%;
    }
  }
}

.profile-delete-user-data {
  display: flex;
  flex-direction: column;
  height: 100%;

  .field-item {
    color: #9b9b9b;
    font: 14px 'Montserrat Light';
    display: block;
    cursor: pointer;
    font-weight: 400;

    input {
      margin-bottom: 30px;
      display: block;
      width: 100%;
      height: 47px;
      background: 0 0;
      background-color: #fafafa;
      border: 1px solid #fafafa;
      border-radius: 3px;
      color: #4a4a4a;
      font-size: 15px;
      padding: 10px;

      &:focus {
        border: 1px solid #339bf2;
      }
    }

    &.has-error {
      input {
        border-bottom: 1px solid red;
      }
    }
  }

  .button {
    margin-top: auto !important;
    letter-spacing: 1px;
    margin: 0 auto 20px auto;
    @media screen and (min-width: 768px) {
      max-width: 300px;
    }
  }
}

.banner-statistics {
  .banner_body-content {
    padding-top: 0;
  }
}
