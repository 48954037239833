@import "../../__common";

.exercise-completed {
  display: flex;
  justify-content: center;
  flex-direction: column;

  @include smartphone {
    margin-bottom: 5px;
    justify-content: stretch;
    margin: 10px;
    width: calc(100% - 20px);
  }

  &__header {
    color: $white;
    display: flex !important;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;

    @include smartphone() {
      border-top-right-radius: 8px;
      border-top-left-radius: 8px;
    }

    &--is-success {
      background-color: $success;
      position: relative;

      &:before {
        content: " ";
        background-size: 210px;
        height: 210px;
        width: 210px;
        background-image: url("../../../assets/icons/corona.svg");
        background-repeat: no-repeat;
        position: absolute;
        top: 55px;
        left: 50%;
        margin-left: -105px;
        animation: exerciseCompletedPopIn 1.5s 1 ease-in-out,
          exerciseCompletedRotate 10s infinite linear;
      }
    }

    &--is-danger {
      background-color: $danger;
    }

    &-icon {
      padding-top: 110px;

      & > .round-button {
        display: block;

        & > .round-button-icon {
          & > svg {
            width: 100% !important;
            height: 100% !important;
          }
        }
      }
    }

    &-description {
      font-size: 24px;
      font-weight: bold;
      margin: 90px 0;
    }
  }

  &__header,
  &__panel {
    display: grid;
    justify-items: center;
  }

  &__panel {
    border-radius: $border-radius;
    background-color: $white;
    box-shadow: $box-shadow;
    justify-content: center;
    @include smartphone() {
      width: 100%;
      max-width: unset;
      border-top-left-radius: unset;
      border-top-right-radius: unset;
      box-shadow: unset;
    }

    @media screen and (min-width: 768px){
      width: 500px;
      margin: 0 auto;
      padding: 20px;

      &-actions{
        flex-direction: column;
        align-items: center;
      }
    }

    &-header {
      display: grid;
      align-content: center;
      padding: 20px 0;

      h2 {
        display: grid;
        margin: 0;
      }
    }

    &-actions {
      display: flex;
      justify-content: space-around;
      border-top: 1px solid $divider;
      padding: 20px 0 30px 0;
      margin: 0 20px;
      max-width: 470px;

      @include desktop() {
        margin: 0;
      }

      .button {
        @include smartphone() {
          margin: 0 20px;
        }

        &-text {
          font-size: 12px;
          font-weight: bold;
          letter-spacing: 1.5px;
          @include smartphone() {
            word-break: break-word;
            letter-spacing: 1px;
            padding: 0;
          }
        }
      }

      .button:last-child {
        .button-text {
          color: $primary-accent-dark;
        }
      }
    }
  }
}

@keyframes exerciseCompletedRotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes exerciseCompletedPopIn {
  0%,
  50% {
    transform: scale(0.01);
  }
  75% {
    transform: scale(1.3);
  }
  100% {
    transform: scale(1);
  }
}
