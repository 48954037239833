@import "../../__common";

.banner {
  display: grid;
  background: $white;
  width: 100%;
  height: 100%;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: $box-shadow;

  &-wrapper {
    padding: 10px;
    height: 100%;

    @media screen and (min-width: 950px) {
      width: 100%;
      margin: 10px 0;
      height: auto;
    }

    &.lineheight{
      line-height: 1.5;
    }

    &.banner-login,
    &.banner-lostpassword,
    &.banner-register{
      height: calc(100% - 60px);

       @media screen and(min-width: 1024px){
         display: grid;
         justify-items: center;
         height: auto;
       }
    }

    &.banner-login,
    &.banner-lostpassword,
    &.banner-register,
    &.banner-subpage,
    &.banner-feedback,
    &.banner-infopage,
    &.banner-legal,
    &.banner-offlinemode{
      .banner{
        display: flex;
        flex-direction: column;

        &_header{
          flex-shrink: 0;
        }

        &_body{
          flex-grow: 1;
          overflow-y: auto;
        }

        form{
          height: 100%;
        }
      }
    }

    &.banner-login,
    &.banner-lostpassword,
    &.banner-register{
      .banner_body-content{
        padding: 20px;
        height: 100%;
      }
    }

    &.banner-subpage,
    &.banner-feedback,
    &.banner-infopage,
    &.banner-legal,
    &.banner-offlinemode{
      height: calc(100% - 66px);

      .banner_body,
      .banner_body-content{
        padding-bottom: 0;
      }

      @media screen and (min-width: 768px){
        height: 100%;
        max-width: 1200px;
        margin: 0 auto;
      }
    }

    &.banner-infopage .banner_body-content,
    &.banner-legal .banner_body-content,
    &.banner-feedback .banner_body-content{
      padding-bottom: 20px;
    }

     &.banner-feedback .banner_body-content{
      height: 100%;
    }

     &.banner-offlinemode .banner_body-content{
      height: 100%;
      display: flex;
      flex-direction: column;

      p{
        font-family: "Montserrat light", sans-serif;
        font-size: 14px;
      }
    }

    &.banner-legal,
    &.banner-infopage {
      p{
        line-height: 1.5;
        margin: 0;
        margin-bottom: 20px;
        font-family: "Montserrat light", sans-serif;
        font-size: 14px;
      }

      li{
        line-height: 1.5;
        font-family: "Montserrat light", sans-serif;
        font-size: 14px;
      }

      h3, h4{
        margin-top: 0;
        margin-bottom: 15px;
      }

      &--onboarding{
        height: 100%;
      }
    }
  }



  h1,
  h1 div {
    font-size: 22px;
    font-family: "Montserrat Bold", sans-serif;
    font-weight: 700;
    margin: 18px 0;

    @media screen and (min-width: 530px) {
      font-size: 27px;
      margin-bottom: 40px;
    }

    @media screen and (min-width: 950px) {
      font-size: 32px;
      margin-bottom: 25px;
    }
  }

  &_header {
    display: grid;
    height: 54px;
    grid-template-columns: 100px 1fr 100px;
    border-bottom: 1px solid $divider;
    padding: 0 20px;
    background: #fff;
    border-radius: 6px 6px 0 0;
    @include smartphone() {
      padding: 0;
      grid-template-columns: 40px 1fr;
    }
    &-action {
      display: grid;
      cursor: pointer;
      justify-content: center;
      align-content: center;
      width: 20px;
    }
    svg {
      width: 42px;
      transform: rotateY(180deg);
      path {
        stroke: $tertiary;
      }
    }
    span {
      display: grid;
      align-self: center;
      justify-content: center;
      font-family: "Montserrat light", sans-serif;

      p {
        width: 100%;
        padding: 0 40px;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  .text {
    font-size: 11px;
    &.text-with-marker {
      text-transform: uppercase;
      font-family: $font-light;
    }
  }
  &_body {
    padding: 20px;
    text-align: center;
    background: #fff;
    border-radius: 0 0 6px 6px;
    // box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);

    @media screen and(min-width: 1050px){
      padding: 30px;
    }


    // *{
    //   line-height: 1.5; // fix this later
    // }

    .button {
      margin-bottom: 30px;
      width: 100%;
      &--has-icon-right {
        padding: 0 85px;
        width: auto;
        position: relative;

        svg {
          top: 4px;
          width: 42px;
          height: 42px;
          right: -4px;
        }

        @media screen and (min-width: 950px) {
          padding: 0;

          .button-icon-right {
            position: absolute;
            right: 0;
          }
        }
      }
    }

    &-content {
      text-align: initial;
      color: $text-main;
      padding: 25px 0;

      .button {
        margin-bottom: 0;
      }

      & h2,
      & h3,
      & h4 {
        text-align: left;
        margin-left: 0;
        margin-right: 0;
      }

      .paragraph div,
      .paragraph span {
        font-size: 14px;
        font-family: "Montserrat", sans-serif;
        font-weight: 400;
      }
    }

    .list-item_content-description {
      padding: 0 0 0 20px;
      flex-grow: 1;
      flex-shrink: 1;

      @media screen and (min-width: 1050px){
        padding: 0 20px;
      }

      .paragraph{
        line-height: 1.7;
      }

      &.nosub div {
        margin-bottom: 0 !important;
      }

      span {
        color: #738ea5;
        font-size: 10px;
        font-family: "Montserrat light", sans-serif;
        text-transform: uppercase;
      }
    }
  }

  .practice-button-wrapper{
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .button{
      @media screen and (min-width: 768px){
        max-width: 300px;
      }
    }
  }
}

.banner_info_description span {
  margin: 0 7.5px;
}
