@import "../../__common";

.content-card {
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 14px 18px;
  background: $white;
  border-radius: 6px;
  box-shadow: $box-shadow;
  width: calc(100% - 28px);
  min-height: 362px;

  @media screen and (min-width: 768px) {
    // display: block;
    width: calc(50% - 30px);
    margin: 0 15px 30px;
  }

  .button-icon-right {
    display: none;

    @media screen and (min-width: 768px) {
      display: block;
    }
  }

  &-header {
    // display: grid;
    height: 70px;
    line-height: 70px;
    padding: 0 25px;
    // align-content: center;
    // justify-content: left;
    text-align: left;

    .tablet-only{
      display: none;
      font-family: "Montserrat light", sans-serif;

      @media screen and (min-width: 768px) {
        display: inline;
      }
    }

    @media screen and (min-width: 768px) {
      border-bottom: 1px solid $divider;
      padding: 0 30px;
    }
  }

  &-body {
    padding: 0 15px 15px;
    word-break: break-word;
    hyphens: auto;
    display: flex;
    flex-direction: column;
    flex-grow: 1;

    &__points {
      margin: 0 30px;
    }

    h2 {
      margin-top: 0;

      @media screen and (min-width: 767px) {
        margin-top: 23px;
      }
    }

    h2, h2 div, h2 div * {
      font-family: "Montserrat Bold", "Montserrat", sans-serif;
    }
  }

  &-headline {
    flex-grow: 1;
  }

  .text {
    font-size: 11px;
    font-family: "Montserrat light", sans-serif;
    &.text-with-marker {
      text-transform: uppercase;
      font-family: $font-light;
    }
  }
  .group-border {
    margin: 25px;
    textarea {
      font-size: 32px;
      text-align: center;
      color: $primary-accent-dark;
      font-family: $font-bold;
      font-weight: bold;
    }
  }
  .divider {
    margin: 25px 15px;
  }

  .button {
    margin: auto auto 25px auto;

    @media screen and (min-width: 767px) {
      margin: auto auto 15px auto;
    }
  }

  //@media screen and (min-width: 1050px) {
  //  width: calc(33.33% - 20px);
  //  margin: 0 10px 30px;
  //}
}
