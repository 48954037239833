 // add app font
@font-face {
  font-family: 'Montserrat';
  src: url('../assets/fonts/montserrat-regular.otf' );
  src: url( '../assets/fonts/montserrat-regular.eot' ); /* IE9 Compat Modes */
  src: local( 'Montserrat' ), local( 'Montserrat Regular' ), local( 'Montserrat-Regular' ),
  url( '../assets/fonts/montserrat-regular.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
  url( '../assets/fonts/montserrat-regular.woff2' ) format( 'woff2' ), /* Super Modern Browsers */
  url( '../assets/fonts/montserrat-regular.woff' ) format( 'woff' ), /* Modern Browsers */
  url( '../assets/fonts/montserrat-regular.ttf' ) format( 'truetype' ), /* Safari, Android, iOS */
  url( '../assets/fonts/montserrat-regular.svg' ) format( 'svg' ); /* Legacy iOS */
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url( '../assets/fonts/montserrat-bold.eot' ); /* IE9 Compat Modes */
  src: local( 'Montserrat Bold' ), local( 'Montserrat-Bold' ),
  url( '../assets/fonts/montserrat-bold.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
  url( '../assets/fonts/montserrat-bold.woff2' ) format( 'woff2' ), /* Super Modern Browsers */
  url( '../assets/fonts/montserrat-bold.woff' ) format( 'woff' ), /* Modern Browsers */
  url( '../assets/fonts/montserrat-bold.ttf' ) format( 'truetype' ), /* Safari, Android, iOS */
  url( '../assets/fonts/montserrat-bold.svg' ) format( 'svg' ); /* Legacy iOS */
}
@font-face {
  font-family: 'Montserrat Light';
  src: url( '../assets/fonts/montserrat-light.eot' ); /* IE9 Compat Modes */
  src: local( 'Montserrat Light' ), local( 'Montserrat-Light' ),
  url( '../assets/fonts/montserrat-light.eot?#iefix' ) format( 'embedded-opentype' ), /* IE6-IE8 */
  url( '../assets/fonts/montserrat-light.woff2' ) format( 'woff2' ), /* Super Modern Browsers */
  url( '../assets/fonts/montserrat-light.woff' ) format( 'woff' ), /* Modern Browsers */
  url( '../assets/fonts/montserrat-light.ttf' ) format( 'truetype' ), /* Safari, Android, iOS */
  url( '../assets/fonts/montserrat-light.svg' ) format( 'svg' ); /* Legacy iOS */
}
