@import "../../__common";

.group-border {
  position: relative;
  border-bottom: 1px solid $divider;
  border-radius: 6px;
  margin: 25px;
}

textarea {
  font-size: 18px;
  padding: 10px 10px 10px 5px;
  display: block;
  width: 100%;
  border: none;
  background-color: transparent;
  color: $primary-accent-dark;
  font-family: $font-bold;
  font-weight: bold;
}
textarea:focus {
  outline: none;
}

label {
  color: $secondary-accent-dark;
  font-size: 18px;
  font-weight: normal;
  position: absolute;
  pointer-events: none;
  left: 5px;
  top: 10px;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
textarea:focus ~ label,
textarea:not(:placeholder-shown) ~ label {
  top: -9px;
}
.bar {
  position: relative;
  display: block;
  width: 100%;
}
.bar:before,
.bar:after {
  content: "";
  height: 2px;
  width: 0;
  bottom: 1px;
  position: absolute;
  background: $primary;
  transition: 0.2s ease all;
  -moz-transition: 0.2s ease all;
  -webkit-transition: 0.2s ease all;
}
.bar:before {
  left: 50%;
}
.bar:after {
  right: 50%;
}

textarea:focus ~ .bar:before,
textarea:focus ~ .bar:after {
  width: 50%;
}

.highlight {
  position: absolute;
  height: 60%;
  width: 100px;
  top: 25%;
  left: 0;
  pointer-events: none;
  opacity: 0.5;
}

textarea:focus ~ .highlight {
  -webkit-animation: textareaHighlighter 0.3s ease;
  -moz-animation: textareaHighlighter 0.3s ease;
  animation: textareaHighlighter 0.3s ease;
}

.group-border:focus-within {
  border-color: transparent;
}

@-webkit-keyframes textareaHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@-moz-keyframes textareaHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
@keyframes textareaHighlighter {
  from {
    background: $primary;
  }
  to {
    width: 0;
    background: transparent;
  }
}
