@import "../../../_common";

body.confirm-finish-exam--is-modal-open {
  overflow: hidden;
}

.confirm-finish-exam {
  &-content {
    display: grid;
    &__body {
      background-color: $white;
      margin: 0 auto;
      overflow: auto;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      text-align: center;
      padding: 20px 20px 10px 20px;
      grid-row-gap: 20px;
    }
  }
  &__icon {
    display: grid;
    justify-content: center;
    align-items: center;

    > .round-button {
      grid-auto-columns: 1fr;
      cursor: default;

      > .round-button-icon {
        width: 100px !important;
        height: 100px !important;
        > svg {
          width: 100% !important;
          height: 100% !important;
          fill: $danger;
        }
      }
    }
  }

  &__headline {
    h2 {
      margin: 0;
    }
  }

  &__question, &__attention {
    font-size: 13px;
    font-family: "Montserrat light", sans-serif;
  }

  &__attention {
    color: $danger;
    font-weight: bold;
    margin-bottom: -15px;
  }

  &__question {
    color: $secondary-accent-dark;
    margin-bottom: 10px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    border-top: 1px solid $divider;

    > .button {
      align-items: center;
      width: 100%;
    }

    & span {
      font: 12px $font-bold;
      letter-spacing: -0.2px;
      font-weight: bold;
    }
  }

}
