
.ReactModal {
  &__Overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    background: rgba(0, 0, 0, 0.35);
    overflow: auto;
    > .ReactModal__Content {
      top: 50%;
      left: 50%;
      right: auto;
      bottom: auto;
      margin-right: -50%;
      transform: translate(-50%, -50%);
      max-height: 100vh;
      max-width: 100vw;
      padding: 0;
      overflow: visible;
      position: relative;
      outline: none;

      > div:first-child {
        display: grid;
        justify-content: stretch;
        text-align: center;
      }
    }
  }
}
