/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  --ion-color-primary: #339bf2;
  --ion-color-primary-rgb: 51,155,242;
  --ion-color-primary-contrast: #000000;
  --ion-color-primary-contrast-rgb: 0,0,0;
  --ion-color-primary-shade: #2d88d5;
  --ion-color-primary-tint: #47a5f3;

  --ion-color-secondary: #ffac1b;
  --ion-color-secondary-rgb: 255,172,27;
  --ion-color-secondary-contrast: #000000;
  --ion-color-secondary-contrast-rgb: 0,0,0;
  --ion-color-secondary-shade: #e09718;
  --ion-color-secondary-tint: #ffb432;

  --ion-color-tertiary: #738ea5;
  --ion-color-tertiary-rgb: 115,142,165;
  --ion-color-tertiary-contrast: #000000;
  --ion-color-tertiary-contrast-rgb: 0,0,0;
  --ion-color-tertiary-shade: #657d91;
  --ion-color-tertiary-tint: #8199ae;

  --ion-color-success: #7be03e;
  --ion-color-success-rgb: 123,224,62;
  --ion-color-success-contrast: #000000;
  --ion-color-success-contrast-rgb: 0,0,0;
  --ion-color-success-shade: #6cc537;
  --ion-color-success-tint: #88e351;

  --ion-color-warning: #ffac1b;
  --ion-color-warning-rgb: 255,172,27;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0,0,0;
  --ion-color-warning-shade: #e09718;
  --ion-color-warning-tint: #ffb432;

  --ion-color-danger: #ff6839;
  --ion-color-danger-rgb: 255,104,57;
  --ion-color-danger-contrast: #000000;
  --ion-color-danger-contrast-rgb: 0,0,0;
  --ion-color-danger-shade: #e05c32;
  --ion-color-danger-tint: #ff774d;

  --ion-color-dark: #20415d;
  --ion-color-dark-rgb: 32,65,93;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #1c3952;
  --ion-color-dark-tint: #36546d;

  --ion-color-medium: #4a4a4a;
  --ion-color-medium-rgb: 74,74,74;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255,255,255;
  --ion-color-medium-shade: #414141;
  --ion-color-medium-tint: #5c5c5c;

  --ion-color-light: #f3f3f3;
  --ion-color-light-rgb: 243,243,243;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0,0,0;
  --ion-color-light-shade: #d6d6d6;
  --ion-color-light-tint: #f4f4f4;
}

