@import "../../__common";

.line-chart {
    box-shadow: $box-shadow;
    background: $white;
    border-radius: 4px;

    canvas {
        max-width: 100%;
    }

    &__no-data-message {
        padding: 120px 0;
        color: $divider;
        font-size: 20px;
        text-transform: uppercase;
        text-align: center;
    }
}
