@import "../../__common";

.round-button {
    display: grid;
    cursor: pointer;
    grid-auto-columns: 38px 1fr;
    column-gap: 10px;
    text-align: left;

    .round-button-text {
        text-transform: uppercase;
        font-size: 12px;
    }

    &--is-primary {
        .round-button-icon {
            color: $primary-accent-light;
            background-color: $primary;
        }
        .round-button-text {
            color: $primary;
        }
        &.round-button--is-transparent {
            .round-button-icon {
                color: $primary;
                background-color: transparent;
                border: 1px solid $primary;
            }
        }
    }

    &--is-secondary {
        .round-button-icon {
            color: $secondary-accent-light;
            background-color: $secondary;
        }
        .round-button-text {
            color: $secondary;
        }
        &.round-button--is-transparent {
            .round-button-icon {
                color: $secondary;
                background-color: transparent;
                border: 1px solid $secondary;
            }
        }
    }

    &--is-tertiary {
        .round-button-icon {
            color: $tertiary-accent-light;
            background-color: $tertiary;
        }
        .round-button-text {
            color: $tertiary;
        }
        &.round-button--is-transparent {
            .round-button-icon {
                color: $tertiary;
                background-color: transparent;
                border: 1px solid $tertiary;
            }
        }
    }

    &--is-success {
        .round-button-icon {
            color: $tertiary-accent-light;
            background-color: $success;
        }
        .round-button-text {
            color: $success;
        }
        &.round-button--is-transparent {
            .round-button-icon {
                color: $success;
                background-color: transparent;
                border: 1px solid $success;
            }
        }
    }

    &--is-danger {
        .round-button-icon {
            color: $tertiary-accent-light;
            background-color: $danger;
        }
        .round-button-text {
            color: $danger;
        }
        &.round-button--is-transparent {
            .round-button-icon {
                color: $danger;
                background-color: transparent;
                border: 1px solid $danger;
            }
        }
    }

    &-icon {
        display: grid;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        grid-column: 1;
        svg {
            width: 25px;
            height: 25px;
            margin: auto;
        }
    }
    &-text {
        display: grid;
        align-content: center;
        grid-column: 2;
    }
}
