@import "../../__common";

.onboarding {
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, .1);
  text-align: center;
  color: #FFF;
  padding: 0 30px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  // margin: 30px 60px;
  margin: 10px;
  // max-width: 320px;
  width: calc(100% - 20px);
  height: calc(100% - 60px);


  &__content {
    position: relative;

    svg {
      position: absolute;
      width: 112%;
      right: -29px;
      top: 1px;
    }

    p {
      padding-top: 419px;
      position: absolute;
    }
  }

  &--step1 {
    background: #339bf2;
  }

  &--step2 {
    background: #ffac1b;

    svg {
      width: 80%;
    }
  }

  &--step3 {
    background: #59c01b;

    svg {
      width: 80%;
      right: -20px;
      top: 10px;
    }
  }

  &__progress-dots {
    position: absolute;
    display: flex;
    justify-content: center;
    top: -47px;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        display: inline-block;
        margin: 0 6px;

        &.current:before {
          background: #FFF;
        }

        &:before {
          content: " ";
          width: 6px;
          height: 6px;
          border-radius: 3px;
          border: 1px solid #FFF;
          display: inline-block;
        }
      }
    }
  }

  &__footer {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
  }

  .low-opacity {
    opacity: .5;
    width: 120px !important;
  }

  &__arrows {
    display: flex;;
    justify-content: space-between;
    margin-left: -73px;
    width: 150%;
  }

  .button {
    &--is-light {
      display: block;
      letter-spacing: 0;
      font-size: 12px;
    }
  }

  &--step4 {
    background: #fff;

    p,
    .button {
      color: #20415D;;
    }

    svg {
      width: 100%;
      right: 0;
      top: 150px;
    }

    .login-btn {
      .button {
        &--is-primary {
          color: $white !important;
          letter-spacing: 2px;
          display: block;
          margin-top: -7px;
          font-size: 14px;
          width: 144px;
        }
      }
    }

    .onboarding__footer {
      margin-bottom: 37px;
    }

  }

}
