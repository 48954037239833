@import "../../__common";

.tab-navigation {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(100px, 170px));
    justify-content: center;
    margin: 0;
    padding: 0;


    &__item {
        display: grid;
        align-content: center;
        margin: 0;
        padding: 14px;
        list-style-type: none;
        cursor: pointer;
        font-size: 14px;
        color: $tertiary;
        text-align: center;
        vertical-align: bottom;
        border-bottom: 2px solid transparent;
        font-family: "Montserrat light", sans-serif;

        &--is-selected {
            color: $primary-accent-dark;
            border-bottom: 2px solid $primary-accent-dark;
        }
    }
}
