@import "../../__common";

.list-item {
  display: grid;
  &.course-select {
    padding: 20px;
    &.active {
      background: $tertiary;
      color: $white;
      border-radius: 4px;
    }
  }


  &--is-clickable {
    cursor: pointer;
  }

  &_content {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img{
      width: 40px;
      height: 40px;
      flex-shrink: 0;
      flex-grow: 0;
    }

    .button{
      display: none !important;
    }

    &-description {
      padding: 0 10px;
      align-content: center;
      text-align: left;

      p {
        margin: 0;
        word-break: break-word;
      }
    }
  }

  &_full-width {
    display: block !important;
  }

  @include smartphone() {
    &--is-selected {
      -webkit-transition: all 0.5s ease;
      -moz-transition: all 0.5s ease;
      -o-transition: all 0.5s ease;
      -ms-transition: all 0.5s ease;
      transition: all 0.5s ease;
      &.list-item--is-primary {
        background: $primary;
      }

      &.list-item--is-secondary {
        background: $secondary;
      }

      &.list-item--is-tertiary {
        background: $tertiary;
      }

      &.list-item--is-success {
        background: $success;
      }

      &.list-item--is-danger {
        background: $danger;
      }
    }
  }

  .checkbox {
    align-self: unset;
    margin-top: 4px;
  }

    @media screen and(min-width: 1050px){
      .button{
        display: flex !important;
        width: 300px !important;
        padding: 0;

        &-text{
          display: inline-block;
          margin: 0 auto;
        }

        &-icon-right{
          position: absolute;
          right: 0;
        }
      }
    }
}
