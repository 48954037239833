@import "../../__common";

.circle {
    display: grid;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    justify-content: center;
    align-content: center;

    &--is-clickable {
        cursor: pointer;
    }

    &--is-primary {
        border: 4px solid $primary;
    }
    &--is-primary-bg {
        background: $primary;
        color: $primary-accent-light;
    }

    &--is-secondary {
        border: 4px solid $secondary;
    }
    &--is-secondary-bg {
        background: $secondary;
        color: $secondary-accent-light;
    }

    &--is-tertiary {
        border: 4px solid $tertiary;
    }
    &--is-tertiary-bg {
        background: $tertiary;
        color: $tertiary-accent-light;
    }

    &--is-success {
        border: 4px solid $success;
    }
    &--is-success-bg {
        background: $success;
        color: $tertiary-accent-light;
    }

    &--is-danger {
        border: 4px solid $danger;
        color: $tertiary-accent-light;
    }
    &--is-danger-bg {
        background: $danger;
    }
}
