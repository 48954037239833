@import "../../../_common";

body.exam-navigation--is-modal-open {
  overflow: hidden;
}

.exam-navigation {
  &-content {
    display: grid;
    max-height: unset !important;
    max-width: unset !important;
    width: 100%;
    height: 100%;
    top: 0 !important;
    left: 0 !important;
    margin: 0 !important;
    transform: unset !important;
    @include desktop() {
      height: calc(100% - 57px);
      margin-top: 57px !important;
    }
    @include tablet() {
      height: calc(100% - 57px);
      margin-top: 57px !important;
    }

    &__body {
      display: unset !important;
      background-color: $white;
      padding: 0 50px 50px 50px;
    }
  }
  &__header {
    display: grid;
    grid-template-columns: 1fr 32px;
    align-items: center;
    height: 130px;
    &-item {
      font-family: "Montserrat light", sans-serif;
      display: grid;
      &--is-danger {
        color: $danger;
      }
      .round-button {
        &-icon {
          border: 2px solid #738ea5 !important;
          font-family: $font-bold !important;
          svg {
            font-family: $font-bold !important;
            width: 26px !important;
            height: 26px !important;
          }
        }
      }

    }
    &-item:first-child {
      justify-content: start;
    }
    &-item:last-child {
      justify-content: end;
    }
  }
  &__question-grid {
    margin-top: 30px;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(38px, 38px));
    grid-row-gap: 18px;
    grid-column-gap: 18px;

    .circle {
      color: $primary-accent-dark;
      font-family: "Montserrat light", sans-serif
    }
  }
  &__header, &__question-grid {
    margin: 30px auto 0 auto;
    // @include desktop() {
    //   max-width: 980px;
    // }
  }
}
.exam-navigation__header-item .round-button-icon {
  border: 0px solid #738ea5 !important;
  font-family: "Montserrat Bold" !important;
  border-radius: 200%;
  svg {
    font-family: $font-bold !important;
    width: 28px !important;
    height: 28px !important;
  }
}