@media screen and (min-width: 1024px) {
  .page-learning,
  .page-exam,
  .page-exercise {
    .page {
      display: grid;
      max-width: 1100px;
      margin: 0 auto;
      grid-template-columns: 40px 1fr 40px;
      grid-column-gap: 20px;
      justify-items: stretch;
    }
  }
  //.page-onboarding {
  //  .page {
  //    display: flex;
  //    justify-content: start;
  //    flex-flow: column;
  //    align-items: center;
  //  }
  //}
}
