@import "../../__common";

.splash {
  margin: 0;
  display:flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  padding: 80px 30px 100px;
  border-radius: $border-radius;
  hyphens: auto;
  word-break: break-word;

  h2 {
    margin: 0;
    color: #fff;
  }

  &__description {
    flex-grow: 1;
    padding-top: 20px;
    .text {
      font-size: 14px;
      color: #fff;
      font-family: "Montserrat light", sans-serif;
      line-height: 21px;
    }
  }

  & .button {
    margin: 0 auto;
    max-width: 300px;

    @media screen and (min-width: 768px){
      width: 300px;
    }
  }

  &__goback{
    color: #fff;
    cursor: pointer;
    text-transform: uppercase;
    padding-top: 25px;
    margin: 0;
    width: auto;
    font-size: 11px;
    font-family: "Montserrat light", sans-serif;

  }
}
