.feedback {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;

  p {
    margin: 0;
    font-size: 14px;
    line-height: 27px;
    font-family:'Montserrat Light';
    color: #4A4A4A;
  }

  textarea.feedback-form-textarea {
    width: 100%;
    height: 100%;
    min-height: 180px;
    flex-shrink: 1;
    border: 1px solid rgb(250,250,250);
    background: rgb(250, 250, 250);
    margin-top: 20px;
    padding: 10px 15px;
    font-family:'Montserrat Light';
    font-weight: normal;
    color: #4a4a4a;
    resize: none;
    flex-grow: 1;
    font-size: 14px;
    border-radius: 3px;
    line-height: 27px;

    &:focus{
      border: 1px solid #339bf2;
    }
  }

  .submit-button {
    margin: 40px auto 20px;
    width: 100%;
    @media screen and (min-width: 768px){
      max-width: 300px;
    }
  }
}
