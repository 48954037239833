.info-badge-link {
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgbA(0 0 0 / 10%);
  margin: 30px 0;
  padding: 0 30px;
  color: #fff;
  background: url('./images/img_interrogationmark.png') 35px -1px no-repeat;
  background-size: 63px 80px;
  display: block;
  height: 70px;
  text-decoration: none;
  font-family: 'Montserrat Light', sans-serif;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.info-badge-link {
    background-color: #FFAC1B; 
}

.info-link-wrapper .info-badge-link .label {
    float: right;
    font-size: 13px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    span{
        display: none;
        @media screen and (min-width: 767px){
            display: inline-block;
        }
    }
}

.info-link-wrapper .info-badge-link .label img, .info-link-wrapper .info-badge-link .label object {
    vertical-align: text-top;
    padding-left: 8px;
}

.right_arrow {
    width: 10px;
    height: 16px;
    background: url('./images/ico_arrow_right_white.svg') no-repeat;
    margin-left: 10px;
}

@media screen and (min-width: 767px){
    .info-link-wrapper {
        margin-left: -60px;
        margin-right: -60px;
    }
}
