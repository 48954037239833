.exercise-filter{
    padding: 0 20px;
    text-align: right;

    select{
        appearance: none;
        -webkit-appearance: none;
        border: transparent;
        font-size: 11px;
        color:#4a4a4a;
        font-family: "Montserrat light";
        background-color: transparent;
        background-image: url("../../../assets/icons/chevron-down.svg");
        background-position: right top 3px;
        background-size: 10px;
        background-repeat: no-repeat;
        padding-right: 15px;
        text-align: right;
        max-width: 100%;

        &:focus,
        &:focus-visible{
            outline: none;
        }
    }
}