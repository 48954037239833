.search-bar {
  position: relative;
  margin: 20px 14px 20px;
  width: calc(100% - 28px);
}

.searchBar.focused {
  border-bottom-color: #339bf2;
}

.searchBar {
  padding: 0;
  margin-top: 0;
  width: 100%;
  border-bottom: 1px solid #ced6dc;
  text-align: center;
  cursor: text;
}

.searchBar .cross {
  position: absolute;
  cursor: pointer;
  margin: 0;
  right: 0;
  top: 3px;
}

.searchBar input {
  font-family: "Montserrat light", sans-serif;
  width: calc(100% - 43px);
  display: inline-block;
  height: 28px;
  background: 0 0;
  border: none;
  color: #20415d;
  font-size: 14px;
  padding: 0 0 0 15px;
}

.search-bar-results {
  position: absolute;
  z-index: 50;
  width: 100%;
  background-color: #f3f3f3;
  background: #fff;
  border-radius: 6px;
  box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.1);
}

.search-bar-results ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

li {
  display: list-item;
  text-align: -webkit-match-parent;
}

.search-bar-results li:first-child a {
  border-top: none;
}

.search-bar-results li a {
  border-top: 1px solid rgba(32, 65, 93, 0.2);
  padding: 15px 20px;
  display: block;
  text-decoration: none;
  color: #339bf2;
}

.search-bar-results-label {
  font-size: 16px;
}

.search-bar-results-type {
  opacity: 0.5;
  padding-top: 3px;
  font-size: 12px;
}
.search-bar-results-label,
.search-bar-results-type {
  font-family: "Montserrat Light";
  color: #0a1722;
}
