.profile-image {
  padding-bottom: 10px;

  .image-upload {
    margin: 0;
    padding: 0;

    .upload-label {
      width: 260px;
      color: #9B9B9B;
      display: flex;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .profile-image-wrapper {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    overflow: hidden;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;

    &.placeholder {
      background: #20415D;
      border-radius: 50px;
    }
  }

  .profile-image-name {
    color: #20415D;
    display: inline-block;
    line-height: 50px;
    text-transform: none;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-family: 'Montserrat Bold';
    font-size: 19px;
    text-transform: capitalize;

  }
}
