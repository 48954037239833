
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 50px;
  div {
    position: absolute;
    top: 18px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: inherit;
    animation-timing-function: cubic-bezier(0, 1, 1, 0);

    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }

  &.tiny{
    height: 20px;
    width: 38px;

    div {
      top: 8px;
      width: 8px;
      height: 8px;

      &:nth-child(1) {
        left: 0px;
      }
      &:nth-child(2) {
        left: 0px;
        animation: lds-ellipsis2tiny 0.6s infinite;
      }
      &:nth-child(3) {
        left: 15px;
        animation: lds-ellipsis2tiny 0.6s infinite;
      }
      &:nth-child(4) {
        left: 30px;
      }
    }
  }
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@keyframes lds-ellipsis2tiny {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(15px, 0);
  }
}
