.loadingOverlay{
    position: absolute;
    left: 0;
    top: env(safe-area-inset-top);
    bottom: env(safe-area-inset-bottom);
    right: 0;
    z-index: 200;
    display: flex;
    align-items: center;
    justify-content: center;
}