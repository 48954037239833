@import "../../__common";

.exercise-wizard {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  @media screen and (min-width: 768px){
    margin-top: -65px;
    height: calc(100% + 65px);
  }

  @media screen and (min-width: 1024px){
    min-width: 922px;
  }

  &__header {
    display: grid;
    grid-column: 1;
    height: 54px;
    grid-template-columns: 1fr 32px;
    grid-column-gap: 10px;
    align-items: center;
    border-bottom: 1px solid $divider;
    padding: 0 20px;

    .text{
      font-family: "Montserrat light", sans-serif;
    }

    &-close {
      grid-column: 2;
    }
  }

  &__panel {
    box-shadow: $box-shadow;
    background-color: $white;
    z-index: 1;
    flex-shrink: 0;
    flex-grow: 0;
    overflow-y: auto;

    @include desktop() {
      border-radius: $border-radius;
    }

    // @media screen and (min-width: 899px) {
    //   margin-top: 15px;
    // }

    h2 {
      font-weight: 400;
      padding: 20px;
      text-align: center;
      font-size: 16px;
      margin: 0;

      .correct-answers-count {
        margin-top: 20px;
      }
    }
  }

  .list {
    background: $secondary-bg;
    padding: 0;
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;

    @media screen and (min-width: 1024px){
      flex-grow: initial;
    }

    &-item {
      font-family: "Montserrat", sans-serif;
      padding-bottom: 0;
      border-bottom: 1px solid $divider;

      &:last-child {
          border-bottom: none;
      }

      &_content {
        display: block;

        &-description{
          padding: 0;
        }
      }
      .checkbox {
        display: none;
      }

      .paragraph, .paragraph div, .paragraph span{
        font-size: 15px;
        line-height: 1.4;
      }
    }
  }

  &__question-evaluated-answers {
    background: $secondary-bg;

    .circle{
      width: 40px;
      height: 40px;
      border-width: 2px;
      margin-top: 4px;
    }

    @include smartphone() {
      padding: 0;
    }

    &-item {
      display: block;
      border-bottom: 1px solid $divider;

      &:last-child {
          border-bottom: none;
      }

      &-columns {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;

        @include smartphone() {
          grid-template-columns: 15px 1fr;
        }
        //
        //@include desktop() {
        //  grid-template-columns: 40px 2fr 1fr;
        //}

        .status {
          grid-column: 1;
          align-self: stretch;

          @include smartphone() {
            .circle {
              display: none;
            }
          }
        }
          .status {
            background: $danger;
          }

          &--is-needing_check {
            .status {
              background: $success;
            }
          }

        .paragraph {
          text-align: left;
          padding: 15px 20px 15px 10px;
          margin: 0;
          font-family: "Montserrat light", sans-serif;
          font-size: 15px;
          line-height: 1.4;
        }

        &--is-checked {
          .paragraph {
            background: $secondary;
          }
        }

        &--is-selected .paragraph{
          background: #ffac1b;
        }
      }
    }
  }

  &__footer {
    display: grid;
    justify-items: center;
    margin: 50px 0;

    .button{
      @media screen and (min-width: 768px){
        max-width: 300px;
      }
    }
  }

  .list-item{
    padding: 15px 20px 15px 25px;
    .paragraph {
      font: 15px Montserrat, sans-serif;
      div {
        margin-bottom: 0;
      }
    }


    @media screen and (min-width:768px){
      padding: 15px 20px 15px 30px;

      .paragraph {
        line-height: 1.4;
      }

      &--is-selected{
        background: #ffac1b;
      }
    }
  }

  .list-item{
    .paragraph {
      div {
        margin-bottom: 0;
      }
    }
  }
}
