@import "../../../_common";

body.result-question--is-modal-open {
  overflow: hidden;
}

.result-question {
  &-content {
    display: grid;

    &__body {
      background-color: $white;
      margin: 0 auto;
      overflow: auto;
      border-radius: $border-radius;
      box-shadow: $box-shadow;
      text-align: center;
      padding: 20px 20px 10px 20px;
      grid-row-gap: 20px;
      justify-content: stretch;
      width: calc(100% - 80px);
      max-width: 650px;
    }
  }

  &__text {
    h2 {
      margin: 20px 30px 30px 30px;
    }
  }

  &__header {
    align-items: center;
    display: flex;
    justify-content: center;
    border-bottom: 1px solid $divider;
    position: relative;
    padding: 5px 0 20px 0;
    width: 106%;
    margin-left: -3%;

    @include smartphone() {
      width: 109%;
      margin-left: -6%;
    }

    &-close {
      grid-column: 2;
      position: absolute;
      right: 0;

      svg {
        width: 26px !important;
      }
    }
  }

  &__answers {
    &-item {
      display: block;

      &:after {
        content: "";
        display: block;
        border-bottom: 1px solid $divider;
        margin: 20px 0 20px 48px;

        @include smartphone() {
          display: none;
        }
      }

      &:last-child {
        &:after {
          border-bottom: none;
        }
      }

      &-columns {
        display: grid;
        grid-template-columns: 40px 1fr;
        align-items: center;

        @include smartphone() {
          grid-template-columns: 15px 1fr;
        }

        .status {
          grid-column: 1;
          align-self: stretch;

          @include smartphone() {
            .circle {
              display: none;
            }
          }
        }

        @include smartphone() {
          .status {
            background: $danger;
          }

          &--is-needing_check {
            .status {
              background: $success;
            }
          }
        }

        .paragraph {
          grid-column: 2;
          justify-self: stretch;
          text-align: left;
          padding: 0 10px;
          margin: 0;
          font-size: 15px;
          line-height: 1.4;
        }

        @include smartphone() {
          .paragraph {
            padding: 20px 10px;
            border-bottom: 1px solid $divider;
          }
          &--is-checked {
            .paragraph {
              background: $secondary;
            }
          }
        }
      }
    }
  }
}
