$link-color: #20415d;
$tab-bar-icon-fill: #728ea6;

$exam-active-color: #ffac1b;
$learning-active-color: #339bf2;
$profile-active-link-color: #7be03e;
$profile-active-color: #59c11a;

.navigation-tab-wrapper {
  position: absolute;
  height: 66px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
  background: #f3f3f3;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  bottom: env(safe-area-inset-bottom);
  top: inherit !important;

  div.icon {
    width: 38px;
    height: 38px;
    margin-left: 3px !important;


    svg path {
      fill: $tab-bar-icon-fill;
    }
  }

  a {
    text-decoration: none !important;
    font-size: 16px;
    color: $link-color;
  }

  &__item {
    min-width: 110px;
    border-bottom: transparent 3px solid;
    margin-left: 22px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.profile {
      margin: 0 12px 0 0;
      div.icon {
        margin-left: 0;
      }
      svg path {
        fill: #fff !important;
      }
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;

      span {
        padding-left: 5px;
        display: none;
        font-family: "Montserrat light", sans-serif;
      }
    }

    &.appIcon {
      display: none;
      margin-left: 30px;
      min-width: 0px;

      .icon{
        display: none;
      }
    }

    &.is-active {
      &.learning {
        border-bottom: $learning-active-color 3px solid;
        svg path {
          fill: $learning-active-color !important;
        }
        a {
          color: $learning-active-color;
        }
      }

      &.exam {
        border-bottom: $exam-active-color 3px solid;
        svg path {
          fill: $exam-active-color !important;
        }
        a {
          color: $exam-active-color;
        }
      }

      &.profile {
        border-bottom: $profile-active-link-color 3px solid;
        .round-button-icon {
          background: $profile-active-color;
        }
      }
    }
  } 

  &__center,
  &__left,
  &__right {
    display: flex;
  }

  @media screen and (min-width: 768px){
    padding: 0 15px;
    justify-content: space-between;
    bottom: auto;
    top: 0;

    &__left,
    &__right{
      width: 220px;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__item{
      width: 100%;
      white-space: nowrap;
      min-width: auto;

      &.appIcon{
        display: flex;
        margin-left: 0;
        justify-content: flex-start;
        width: 100%;

        a, span{
          width: 100%;
          display: inline-block;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        a{
          padding-top: 5px;
        }
      }

      a span{
        display: inline-block;
      }
    }

    &__center{
      .navigation-tab-wrapper__item{
        width: auto;
        padding: 0 10px;
        margin: 0 10px;
      }
    }

    &__right{
      justify-content: flex-end;

      .navigation-tab-wrapper__item{
        width: auto;

        a{
          flex-direction: column;
        }
      }
    }

    div.icon{
      margin-left: 0;
    }
  }
}
