@import "../../__common";

.radiobutton {
    width: 40px;
    height: 40px;
    margin: 2px 0 2px 0;
    background: #ddd;
    border-radius: 100%;
    position: relative;

    input[type="radio"] {
        opacity: 0;
        height: 40px;
    }
    &--is-primary {
        background: $primary;
        input[type="radio"]:checked + label.inner {
            background: $primary;
        }
    }
    &--is-secondary {
        background: $secondary;
        input[type="radio"]:checked + label.inner {
            background: $secondary;
        }
    }
    &--is-tertiary {
        background: $tertiary;
        input[type="radio"]:checked + label.inner {
            background: $tertiary;
        }
    }
    label.inner {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 100px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
        cursor: pointer;
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 1;
        background: $secondary-bg;
    }

    label.outer {
        margin-left: 25px;
    }
}
