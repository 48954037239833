@import "../../__common";

.checkbox {
    display: grid;
    align-self: center;
    width: 40px;
    height: 40px;
    background: $checkbox-default-border;
    border-radius: 100%;
    position: relative;

    input[type="checkbox"] {
        margin: 0;
        opacity: 0;
        height: 40px;
    }
    &:before {
        content: ' ';
        background-size: 28px;
        height: 40px;
        width: 40px;
        z-index: 2;
        background-repeat: no-repeat;
        position: absolute;
        pointer-events: none;
        left: 5px;
        top: 7px;
    }
    &--is-primary {
        input[type="checkbox"]:checked + label.inner {
            background: $primary;
        }
        &.checked,
        &.colored {
            background: $primary;
        }
    }
    &--is-secondary {
        input[type="checkbox"]:checked + label.inner {
            background: $secondary;
        }
        &.checked,
        &.colored  {
            background: $secondary;
        }
    }
    &--is-tertiary {
        input[type="checkbox"]:checked + label.inner {
            background: $tertiary;
        }
        &.checked,
        &.colored{
            background: $tertiary;
        }
    }
    &--is-success {
        input[type="checkbox"]:checked + label.inner {
            background: $success;
        }
        &.checked,
        &.colored {
            background: $success;
        }
    }
    &--is-danger {
        input[type="checkbox"]:checked + label.inner {
            background: $danger;
        }
        &.checked,
        &.colored {
            background: $danger;
        }
    }
    label.inner {
        display: block;
        width: 36px;
        height: 36px;
        border-radius: 100px;
        -webkit-transition: all 0.5s ease;
        -moz-transition: all 0.5s ease;
        -o-transition: all 0.5s ease;
        -ms-transition: all 0.5s ease;
        transition: all 0.5s ease;
        cursor: pointer;
        position: absolute;
        top: 2px;
        left: 2px;
        z-index: 1;
        background: $secondary-bg;
    }

    &-label {
        position: inherit;
        display: grid;
        justify-content: left;
        margin: 0 0 5px 0;
    }
    &.check-icon {
        &:before {
            background-image: url('./check.svg') !important;
        }
    }

}
