@import "../../__common";

.text {
    color: $secondary-accent-dark;
    font-size: 10px;
    &--is-primary {
        color: $primary;
        &:before {
            background: $primary;
        }
    }

    &--is-secondary {
        color: $secondary;
        &:before {
            background: $secondary;
        }
    }

    &--is-danger {
        color: $danger;
        &:before {
            background: $danger;
        }
    }

    &--is-tertiary {
        color: $tertiary;
        &:before {
            background: $tertiary;
        }
    }

    &--is-overflow {
        overflow: hidden;
    }

    &--is-uppercase {
        text-transform: uppercase;
    }

    &-with-marker:before {
        display: inline-block;
        width: 14px;
        height: 14px;
        border-radius: 7px;
        content: " ";
        margin: 0 6px -2.5px 5px;
    }
}
