.is-android {
  @media screen and (min-width: 600px) and (max-width: 1200px) {
    .page {
      margin-top: 50px !important;
      height: 95%;
    }
    .onboarding__footer {
      position: relative !important;
      display: flex !important;
      justify-content: space-between !important;
      margin-bottom: 30px !important;
      width: 100% !important;
      max-width: 100% !important;
    }
  }
}
@media only screen and (orientation: portrait) {
  .onboarding__footer {
    position: relative !important;
    display: flex !important;
    justify-content: space-between !important;
    margin-bottom: 30px !important;
    width: 100% !important;
    max-width: 100%;
  }
  .wrap-on-tablet {
    display: block;
    flex-wrap: wrap;
  }
}
.exam-completed {
  margin-top: 10px !important;
}

@media only screen and (orientation: landscape) {
  .exam-completed__header,
  .exam-completed__panel {
    display: grid;
    justify-items: center;
    min-width: 500px;
    height: 250px;
  }
  .exam-completed__header-icon {
    padding-top: 31px !important;
  }
  .exam-completed__header-description {
    font-size: 39px !important;
    font-weight: bold;
    margin: 30px 0 !important;
  }
  .exercise-completed__header,
  .exercise-completed__panel {
    display: grid;
    justify-items: center;
    min-width: 500px;
  }
  .exercise-completed__header-description {
    font-size: 39px !important;
    font-weight: bold;
    margin: 60px 0 !important;
  }

  .exam-wizard {
    max-width: 700px !important;
    width: 700px;
  }

  .exam-wizard__panel {
    width: 700px !important;
  }

  .exam-wizard__header {
    width: 700px !important;
    padding: 16px 20px 0 20px !important;
    z-index: 2;
  }
  .page-onboarding {
    .page {
      @media screen and (max-width: 766px) {
        margin: auto !important;
        display: contents !important;
      }
    }
  }

  .onboarding {
    max-width: unset !important;
    width: 400px !important;
    height: 700px !important;
  }
}
.exam-wizard__header-item--is-right .button {
  font-size: 14px;
  border-width: 4px !important;
  width: 100% !important;
  padding: 0 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.page-exam.tablet.is-android {
  .list-item_content {
    display: flex;
    align-items: center;
  }
}

.page-learning.is-android {
  .search-bar {
    padding: 0 8px;
  }
  .page-wrapper {
    height: auto;
  }
}

// .plt-tablet {
//   @media only screen and (orientation: landscape) {
//     .page-profile .sidebar {
//       top: 0px;
//     }
//   }
// }
