@import "../../__common";


.lost-password-form {
  height: 100%;
  display: flex;
  // grid-template-rows: 1fr 1fr;
  flex-direction: column;

  form{
    display: flex;
    flex-direction: column;
  }

  &__inputs{
    flex-grow: 1;
  }

  &__inputs,
  &__actions {
    // display: grid;
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 320px;
    margin: auto;
  }
  &__actions {
    // grid-template-rows: 1fr 0.5fr;
    .button {
      margin-top: 80px;
    }

    a.link {
      margin-top: 30px;
      cursor: pointer;
      text-transform: uppercase;

      *{
        font-family: "Montserrat light", sans-serif;
      }
    }
  }

  &__field {
    span.label {
      color: #9b9b9b;
      margin-left: 10px;
      margin-bottom: 7px;
      display: inline-block;
      font-weight: 400;
      font-size: 15px;
      font-family: "Montserrat light", sans-serif;
    }

    input {
      font-weight: 400;
      font-family: "Montserrat light", sans-serif;
      margin-bottom: 20px;
      display: block;
      width: 100%;
      height: 47px;
      background: 0 0;
      background-color: #fafafa;
      border: 1px solid #fafafa;
      border-radius: 3px;
      color: #20415d;
      font-size: 15px;
      padding: 10px;

      &:focus{
        border: 1px solid #339bf2;
      }

      &:-internal-autofill-selected {
        -webkit-appearance: menulist-button;
        background-color: $login-form-autofill-color !important;
        background-image: none !important;
        font-size: 15px !important;
        font-family: $font-light !important;
      }
    }
  }

  p{
    text-align: left;
    font-weight: 400;
    font-family: "Montserrat light", sans-serif;
    line-height: 1.5;
    padding-bottom: 40px;
    margin: 0;
  }
}