@import '../../../common/__common.scss';

.results-chart {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: $box-shadow;
  width: calc(100% + 30px);
  margin-left: -15px;
  padding: 15px 0;
  margin-bottom: 18px;

  &:last-child{
    margin-bottom: 0;
  }
  
  .apexcharts-xaxistooltip{
    display: none;
  }

  &__filter{
    display: flex;
    padding: 0;
    justify-content: flex-end;
    align-items: center;
    &-legend{
      font-size: 11px;
      color:#758FA6;
    }
    &-item{
      margin-left: 10px;
      border-radius: 20px;
      padding: 7px 9px;
      background-color: #f4f4f4;
      color:#4a4a4a;
      font-size: 11px;
      transition: .2s;
      cursor: pointer;
      font-family: "Montserrat light", sans-serif;

      &--active{
        background-color: #339bf2;
        color: #fff;

      }
    }
  }

  &__header {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px 20px 10px 20px;

    h2{
      font-size: 15px;
      margin: 0;
    }
  }

  &__panel {
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    grid-column-gap: 20px;
    justify-items: stretch;

    & > .previousDataWindowAction .round-button {
      grid-column: 1;
      margin-left: 10px;
    }

    .line-chart {
      grid-column: 2;
      padding: 0 10px;
    }

    & > .previousDataWindowAction .round-button {
      grid-column: 3;
      margin-right: 10px;
    }

    & > .dataWindowAction .round-button { // TODO some of this has to be defined in styleguide
      grid-template-columns: initial;
      grid-auto-columns: 30px 1fr;
      margin-top: 130px;

      &-icon {
        grid-column: 1;
        width: 30px;
        height: 30px;

        & svg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }

  &__buttons {
    grid-column: 2;
    justify-self: right;
  }

  &__detail-button{
    display: flex;
    font-weight: 600;
    font-size: 12px;
    align-items: center;
    justify-content: flex-end;
    color: #F5AD24;
    text-transform: uppercase;
    padding: 10px 20px 20px;
    cursor: pointer;
    
    span{
      font-family: "Montserrat light", sans-serif;
      margin-right: 10px;
    }
  }

  @media screen and (min-width: 1050px){
    margin-left: 0;
    width: 100%;
  }
}

